<template>
  <div>
    <b-card>
      <b-table
        ref="table"
        :items="getReclaims"
        :fields="fields"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        responsive
      >
        <template #cell(actions)="{item}">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{name:'organization-reclaim',params:{id:item.id} }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>
            <b-dropdown-item @click="approveReclaim(item.id)">
              <feather-icon icon="ToggleRightIcon" />
              <span class="align-middle ml-50">Approve</span>
            </b-dropdown-item>
            <b-dropdown-item @click="rejectReclaim(item.id)">
              <feather-icon icon="ToggleLeftIcon" />
              <span class="align-middle ml-50">Reject</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>

<script>
import paginationData from '@/common/compositions/common/paginationData'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Reclaims from '@/common/compositions/Reclaims/ReclaimsAPI'

export default {
  name: 'ReclaimsList',
  components: { Pagination },
  data() {
    return {

    }
  },
  computed: {
    fields() {
      return [
        { key: 'title', formatter: v => v.slice(0, 30) },
        'source',
        'startDate',
        'endDate',
        'attendeesCount',
        { key: 'organization.name', label: 'Org Name' },
        { key: 'user.email', label: 'user email' },
        'status',
        { key: 'isNewOrganization', formatter: v => (v ? 'Yes' : 'No'), label: 'New Organization' },
        'actions',
      ]
    },
  },
  setup() {
    const { pagination } = paginationData()
    const { indexRequest, approveRequest, rejectRequest } = Reclaims()
    const { successfulOperationAlert, confirmOperation } = handleAlerts()
    return {
      pagination, successfulOperationAlert, indexRequest, approveRequest, rejectRequest, confirmOperation,
    }
  },
  methods: {
    getReclaims() {
      return this.indexRequest().then(res => {
        this.pagination.totalRows = res.data.pagination.total
        return res.data.data
      })
    },
    approveReclaim(id) {
      this.confirmOperation('Please view the organization details before approve the reclaim.', 'Yes').then(() => {
        this.approveRequest(id).then(() => {
          this.successfulOperationAlert('Reclaim is approved successfully')
        })
      })
    },
    rejectReclaim(id) {
      this.confirmOperation('Please view the organization details before reject the reclaim.', 'Yes').then(() => {
        this.rejectRequest(id).then(() => {
          this.successfulOperationAlert('Reclaim is rejected successfully')
        })
      })
    },
  },
}
</script>

<style>

</style>
